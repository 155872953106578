import smoothscroll from 'smoothscroll-polyfill';
import 'focus-visible';

// import './src/styles/colors.css';
// import './src/styles/tailwind.css';
import './src/styles/global.css';

export const onClientEntry = () => {
  smoothscroll.polyfill();
};
